html {
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

body {
  font: normal 100% / normal Arial, Helvetica, sans-serif;
  margin: 0;
  font-size: 16px;
  height: 100%;
}

.noselect {
  -webkit-user-select: none;    /* Safari */
  -webkit-touch-callout: none;  /* iOS Safari */
  -khtml-user-select: none;     /* Konqueror HTML */
  -ms-user-select: none;        /* Internet Explorer/Edge */
  -moz-user-select: none;       /* Old versions of Firefox */
  user-select: none;            /* Non-prefixed version*/
}
/* -5px to prevent map caused flickering */

div#app {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

#banner {
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
#banner video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex: 1;

  /* IE 11 flex not growing vertically with flex-basis: 0; - default on flex: 1; */
  flex-basis: auto;
}

.container {
  margin: auto;
  padding-top: 20px;
  text-align: center;
  max-width: 700px;
}

.card-heading {
  padding: 16px;
}

#app-title {
  text-transform: none;
  margin-left: 24px;
}

.live-map-feature-info {
  overflow: auto;
  position: absolute;
  right: 3px;
  top: 3px;
  min-width: 270px;
  width: 25%;
  margin-left: 16px;
  z-index: 10;
  max-height: 100%;
}

.navbar-fill-div {
  flex: 1;
}

.navbar {
  position: fixed !important;
  box-shadow: none !important;
  z-index: 1100 !important;
}

.navbar > .toolbar {
  padding-left: 0;
  padding-right: 0;
}

.main-content {
  overflow: hidden;
  display: flex;
  flex: 1 1 auto;
  padding-top: 48px;
  margin-bottom: 10px;
}

.bottom-margin-md {
  margin-bottom: 20px;
}

.grid-wrapper {
  padding-top: 10px;
  position: relative;
  cursor: grab;
  padding-bottom: 64px;
}

.full-container {
  width: 100%;
  padding: 0 20px;
}

.navbar-menu-button {
  margin-left: 5px !important;
}

.table-container {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
}

.walkthrough {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media only screen and (max-width: 720px) {
  .walkthrough {
    top: 50px;
    left: 0;
  }
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: inherit;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #3f51b5;
  border-radius: 20px;
  border: 0;
}

*::-webkit-scrollbar-corner {
  background: inherit;
}

.tab-content-inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}