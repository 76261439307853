.badge-off span {
  background-color: #df0101 !important;
}

.badge-on span {
  background-color: #04b404 !important;
}

.label-loading {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgb(0, 101, 255);
}

.label-loading svg {
  margin-right: 5px;
}

.label-off {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgb(255, 153, 31);
}

.label-off svg {
  margin-right: 5px;
}

.label-on {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgb(0, 135, 90);
}

.label-on svg {
  margin-right: 5px;
}

.label-missing {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgb(222, 53, 11);
}

.label-missing svg {
  margin-right: 5px;
}

.gateway-info {
  padding: 0 10px;
  float: left;
}

.gateway {
  display: block;
  font-size: 9px;
}

.gateway.device {
  font-style: italic;
  color: #035703;
}

.global-controls {
  display: inline-block;
  margin-right: 30px;
}
