.no-notifications {
  padding: 40px 40px;
  text-align: center;
  font-style: italic;
  color: rgba(0, 0, 0, 0.54) !important;
}

.notification-unread,
.notification-dismiss {
  cursor: pointer;
  margin: 0 10px;
  opacity: 0.4;
}

.notification-unread:hover,
.notification-dismiss:hover {
  opacity: 1;
}

ul.notifications-list {
  padding-top: 0;
  padding-bottom: 0;
}
