.floating-notification {
  opacity: 0;
  margin-bottom: 10px;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  -moz-transition: opacity 500ms ease-in;
  -o-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  -webkit-transition: opacity 500ms ease-in;
  -moz-transition: opacity 500ms ease-in;
  -o-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}
