
.tasks-slider-container {
  position: relative;
  padding-top: 12px;
  cursor: pointer;
  border: 1px solid #e1e1e1;
  height: 40px;
  background-color: #FCFCFC;
}

.tasks-timeslider-container {
  position: relative;
}

.tasks-timeslider-container .scale {
  position: absolute;
  top: -1px;
  color: #666;
  padding-left: 2px;
  font-size: 10px;
  pointer-events: none;
}

.tasks-container {
  padding: 5px;
  border-left: 1px solid #E1E1E1;
  border-right: 1px solid #E1E1E1;
}

.tasks-line {
  position: relative;
  padding: 3px;
  height: 40px;
  border-bottom: 1px solid #E1E1E1;
}

.task {
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
}
