.loading-button {
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
}

.loading-button-wrapper {
  position: relative;
  display: inline;
}
