.chat-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 360px;
  height: 100%;
}

.header {
  background-color: #f1f1f1;
}