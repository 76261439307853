.menu-icon-container {
  position: relative;
}
.menu-shortcut-btn {
  position: absolute !important;
  left: 12px;
  top: 12px;
  float: left;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  overflow-y: auto;
}

button.menu-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999999;
}

.menu-footer {
  width: 100%;
  text-align: center;
}