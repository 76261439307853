.floating-loading-hoc {
  z-index: 1;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  top: 50%;
  margin-top: -40px;
  pointer-events: none;
}

.floating-loading-hoc > p {
  margin-top: 10px;
}
