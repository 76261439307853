.notifications-menu {
  max-width: 400px;
  max-height: 350px;
}

@media only screen and (max-width: 720px) {
  .notifications-menu {
    max-width: 100%;
    max-height: 250px;
  }
}

.notifications-menu-title {
  min-width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-menu > div::-webkit-scrollbar {
  width: 7px;
}

.notifications-menu > div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}

.notifications-menu > div::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 3px;
}
