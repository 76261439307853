.setup-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.setup-title {
  margin: 20px !important;
}

.setup-stepper {
  min-width: 60%;
  background: inherit !important;
}

.setup-steps {
  display: flex;
  flex: 1;
  width: calc(100% - 40px);
  margin: 0 20px 0 20px;
  text-align: center;
  justify-content: center;
}

.step-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  box-shadow: none;
}

#update-floorplan-file-upload {
  display: none;
}

.CustomHelper__wrapper {
  display: flex;
  background-color: #f8f8f8;
  max-width: 490px;
  border-radius: 6px;
  border: 1px solid #c1c1c1;
}

.CustomHelper__content {
  flex: 2;
  padding: 2em;
  padding-bottom: 5em;
  position: relative;
  background-color: #ffffe4;
  border-radius: 6px;
  border-radius: 6px;
}

.CustomHelper__controls {
  position: absolute;
  bottom: 2em;
  left: 0;
  right: 0;
}

.CustomHelper__navArrow {
  margin-right: 10px;
  margin-left: 10px;
}
