.dashboard-wrapper {
  position: relative;
  display: flex;
  margin-top: 48px;
  flex: 1 1 auto;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #fcfcfc;
}

.shortcut-menu {
  width: 56px;
  overflow-y: auto;
  border-radius: 0px !important;
}

@media only screen and (max-width: 720px) {
  .shortcut-menu {
    display: none;
  }
}

.tab-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: auto;
  width: calc(100% - 56px);
}

.dashboard-map-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevron {
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.composed-dtr-with-hot-dates {
  display: flex;
}

@media only screen and (max-width: 720px) {
  .composed-dtr-with-hot-dates {
    flex-direction: column;
    align-items: stretch;
  }
}

/* Fix for mui datatables layout issue on mobile */
@media (max-width: 959.95px) {
  td {
    height: auto !important;
  }
}
