.editor-card {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.editor-container {
  position: relative;
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.editor-content {
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 1 1 100%;
  padding: 5px;
}

.action-bar {
  display: flex;
  width: 58px;
  flex-direction: column;
  border-left: 1px solid #e1e1e1;
  z-index: 999;
  padding: 5px;
}

.popup-card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 300px;
  border-radius: 4px 0px 0px 4px;
  border-right: 1px solid #e1e1e1;
  box-shadow: none;
  z-index: 999;
}