.map-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  /* This position relative is crucial for the tooltip
   * to show in the correct place. */
  position: relative;
}

.map-filter-container {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
}

.map-filter-layers {
  align-items: center;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
}