.bar {
  position: relative;
  display: block;
  height: 4px;
  width: 0;
  border: 1px;
  background-color: #9b59b6;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.weak {
  width: 33.3% !important;
  background-color: #e74c3c;
}

.medium {
  width: 66.6% !important;
  background-color: #e67e22;
}

.strong {
  width: 100% !important;
  background-color: #2ecc71;
}

input:focus ~ .bar,
input:valid ~ .bar {
  width: 100%;
}
