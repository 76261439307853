.task-container {
  min-height: 20px;
  background-color: #FAFAFA;
}

.task-container-droparea {
  border: 1px dashed #CCC;
  min-height: 50px;
  background-color: #FAFAFA;
  padding: 10px;
  padding-bottom: 0px;
}