.image-list-container {
  background-color: #fff;
  border: 1px solid #fcfcfc;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
}

.view-caption {
  color: #999999;
}