.composer-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.composer-dragger {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  border: 1px solid #E1E1E1;
  overflow: hidden;
}