div.custom-color-scale {
  pointer-events: none;
  position: absolute;
  top: 80px;
  right: 50px;
  height: 250px;
  width: 30px;
  z-index: 1;
}

div.custom-color-scale-square {
  height: 1px;
}

b.custom-color-scale {
  position: absolute;
  color: #222;
  font-size: 10px;
  width: 70px;
  top: -20px;
  padding-left: 35px;
  text-shadow: 
    /* first layer at 1px */
    -1px -1px 0px #fff,
    0px -1px 0px #fff,
    1px -1px 0px #fff,
    -1px  0px 0px #fff,
    1px  0px 0px #fff,
    -1px  1px 0px #fff,
    0px  1px 0px #fff,
    1px  1px 0px #fff,
    /* second layer at 2px */
    -2px -2px 0px #fff,
    -1px -2px 0px #fff,
    0px -2px 0px #fff,
    1px -2px 0px #fff,
    2px -2px 0px #fff,
    2px -1px 0px #fff,
    2px  0px 0px #fff,
    2px  1px 0px #fff,
    2px  2px 0px #fff,
    1px  2px 0px #fff,
    0px  2px 0px #fff,
    -1px  2px 0px #fff,
    -2px  2px 0px #fff,
    -2px  1px 0px #fff,
    -2px  0px 0px #fff,
    -2px -1px 0px #fff;
}

i.custom-color-scale {
  position: absolute;
  color: #333;
  font-size: 10px;
  font-style: normal;
  padding-left: 35px;
  text-shadow: 
    /* first layer at 1px */
    -1px -1px 0px #fff,
    0px -1px 0px #fff,
    1px -1px 0px #fff,
    -1px  0px 0px #fff,
    1px  0px 0px #fff,
    -1px  1px 0px #fff,
    0px  1px 0px #fff,
    1px  1px 0px #fff,
    /* second layer at 2px */
    -2px -2px 0px #fff,
    -1px -2px 0px #fff,
    0px -2px 0px #fff,
    1px -2px 0px #fff,
    2px -2px 0px #fff,
    2px -1px 0px #fff,
    2px  0px 0px #fff,
    2px  1px 0px #fff,
    2px  2px 0px #fff,
    1px  2px 0px #fff,
    0px  2px 0px #fff,
    -1px  2px 0px #fff,
    -2px  2px 0px #fff,
    -2px  1px 0px #fff,
    -2px  0px 0px #fff,
    -2px -1px 0px #fff;
}

.custom-color-scale-0 {
  top: calc(0px + 8px);
}

.custom-color-scale-1 {
  top: calc(250px / 10 + 6px);
}

.custom-color-scale-2 {
  top: calc(250px / 10 * 2 + 4px);
}

.custom-color-scale-3 {
  top: calc(250px / 10 * 3 + 2px);
}

.custom-color-scale-4 {
  top: calc(250px / 10 * 4);
}

.custom-color-scale-5 {
  top: calc(250px / 10 * 5 - 2px);
}

.custom-color-scale-6 {
  top: calc(250px / 10 * 6 - 4px);
}

.custom-color-scale-7 {
  top: calc(250px / 10 * 7 - 6px);
}

.custom-color-scale-8 {
  top: calc(250px / 10 * 8 - 8px);
}

.custom-color-scale-9 {
  top: calc(250px / 10 * 9 - 10px);
}

.custom-color-scale-10 {
  top: calc(250px - 12px);
}
