.form-floorplanconfiguration-title {
  background-color: #3f51b5;
  padding: 8px;
}

.form-floorplanconfiguration-title h2 {
  color: #fff;
}

.form-floorplanconfiguration-title button {
  margin-right: 10px;
  color: #CDCDCD;
}
