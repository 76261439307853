.activity {
  display: flex;
  position: relative;
  flex: 1;
  padding-bottom: 32px;
}

.timeslider {
  margin-top: 10px;
  overflow: visible;
  position: relative;
}

.timeslider-content {
  padding: 0px !important;
  text-align: center;
}

.activity-container {
  position: absolute;
  width: 100%;
  bottom: 0px;
  transition: max-height 0.25s ease-in;
  z-index: 1;
  overflow-y: scroll;
  border: 1px solid #ECECEC;
}
.activity-container::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

.activity-toggler {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 0.75rem;
  font-weight: 500;
  color: #636363;
  background-color: rgb(249, 249, 249);
  align-items: center;
  cursor: pointer;
  z-index: 2;
  border-bottom: 1px solid #ECECEC;
}

.activity-toggler-scroller {
  position: relative;
}

.timeslider-pointer {
  position: absolute;
  top: 3px;
  width: 2px;
  background-color: rgb(63, 81, 181);
  height: calc(100% - 3px);
  z-index: 1;
}