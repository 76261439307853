.map-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.map-tooltip {
  position: absolute;
  overflow: visible;
  padding: 5px 15px;
  z-index: 10;
}

.map-scale-line {
  background: rgba(0, 60, 136, 0.3);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}

.map-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
}

.map-window {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  min-height: 0;
}

.map-control {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-right: 1px solid #e1e1e1;
}

.map-control button {
  display: block;
  clear: both;
  pointer-events: all;
  margin-top: 7px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.7);
}

.map-control button:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.map-canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

canvas.map {
  width: 100%;
  height: 100%;
}

canvas.map[resize] {
  width: 100%;
  height: 100%;
}
