.chat {
  --rad: 20px;
  --rad-sm: 3px;
  font: 16px/1.5 sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.chat-bubble {
  position: relative;
  max-width: 75%;
  padding: 7px 15px;
  margin-bottom: 2px;
}

.chat-bubble.sent {
  border-radius: var(--rad) var(--rad-sm) var(--rad-sm) var(--rad);
  background: #42a5f5;
  color: #fff;
  margin-left: auto;
}

.chat-bubble.received {
  border-radius: var(--rad-sm) var(--rad) var(--rad) var(--rad-sm);
  background: #f1f1f1;
  color: #555;
  margin-right: auto;
}

.chat-bubble.sent.broadcast {
  background: #ffab3e;
}
.chat-bubble.received.broadcast {
  background: #ffab3e;
}

.chat-bubble.sent:first-child,
.chat-bubble.received+.chat-bubble.sent {
  border-top-right-radius: var(--rad);
}

.chat-bubble.received:first-child,
.chat-bubble.sent+.chat-bubble.received {
  border-top-left-radius: var(--rad);
}

.chat-bubble::before {
  content: attr(data-time);
  font-size: 0.8rem;
  position: absolute;
  bottom: 100%;
  color: #888;
  white-space: nowrap;
  display: none;
}

.chat-bubble.sent::before {
  right: 15px;
}

.chat-bubble.received::before {
  left: 15px;
}

.chat-bubble:first-child::before,
.chat-bubble.sent+.chat-bubble.received::before,
.chat-bubble.received+.chat-bubble.sent::before {
  display: block;
}

.chat-bubble-i {
  font-size: 0.5rem;
  color: #BBB;
  margin-top: 3px;
  margin-bottom: 10px;
}
.chat-bubble-i.sent {
  text-align: right;
}
.chat-bubble-i.received {
  text-align: left;
}

.chat-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 0px !important;
}