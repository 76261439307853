.loading-hoc {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.loading-hoc > p {
  margin-top: 10px;
}
