.slider-strip {
  position: relative;
  height: 20px;
}

.events-slider-container {
  position: relative;
  padding-top: 6px;
  cursor: pointer;
  border: 1px solid #e1e1e1;
}

.event-slider {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.event-slider .chunk {
  position: absolute;
  top: 0;
  height: 20px;
  display: table;
  white-space: nowrap;
}

.event-slider .event-info {
  position: absolute;
  top: -20px;
  z-index: 999;
  pointer-events: none;
  transform: translate(50%, 0);
}

.event-slider .event-info .buble {
  position: relative;
  background: #3f51b5;
  border-radius: 0.4em;
  padding: 5px;
  color: #fff;
  font-size: 11px;
  top: -5px;
  margin-left: -100%;
}

.event-slider .event-info .buble::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #3f51b5;
  border-bottom: 0;
  margin-left: -5px;
  margin-bottom: -5px;
}

.event-timeslider {
  height: 2px;
  background-color: #e1e1e1;
  padding: 6px;
}

.event-timeslider-container {
  position: relative;
}

.event-timeslider-container .scale {
  position: absolute;
  top: -1px;
  color: #666;
  padding-left: 2px;
  font-size: 10px;
  pointer-events: none;
}

.event-slider-handle {
  position: absolute;
  margin-top: -13px;
  margin-left: -6px;
  z-index: 2;
  width: 14px;
  height: 14px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgb(63, 81, 181);
}

.event-slider-handle:hover {
  margin-left: -8px;
  margin-top: -14px;
  width: 18px;
  height: 18px;
}

.rule-alerts-slider {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.rule-alerts-slider .chunk {
  position: absolute;
  top: 0;
  display: table;
  white-space: nowrap;
  pointer-events: auto;
}

.rule-alerts-slider .alert-info {
  position: absolute;
  top: -20px;
  z-index: 999;
  pointer-events: none;
}

.rule-alerts-slider .alert-info .buble {
  position: relative;
  border-radius: 0.4em;
  padding: 5px;
  color: #fff;
  font-size: 11px;
  top: -5px;
  margin-left: -100%;
}

.rule-alerts-slider .alert-info .buble-v {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom: 0;
  margin-left: calc(-50% - 5px);
  margin-bottom: -4px;
}
