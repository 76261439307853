.range-slider-wrapper {
  padding: 12px;
  height: 24px;
  width: 100%;
}

@media only screen and (max-width: 720px) {
  .range-slider-wrapper {
    margin: 10px 0;
  }
}

.range-slider {
  position: relative;
  width: 100%;
}

.range-slider-handle {
  position: absolute;
  margin-left: -6px;
  margin-top: -5px;
  z-index: 2;
  width: 12px;
  height: 12px;
  cursor: pointer;
  border-radius: 50%;
}

.range-slider-handle:hover {
  margin-left: -8px;
  margin-top: -8px;
  width: 18px;
  height: 18px;
}

.range-slider-track {
  position: absolute;
  height: 2px;
  z-index: 1;
  cursor: pointer;
}

.range-slider-rail {
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #bdbdbd;
}
