table#schedule {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
table#schedule td {
  cursor: pointer;
  padding: 3px;
  border-right: 1px solid #E1E1E1;
  position: relative;
}
table#schedule td:hover {
  background-color: #F1F1F1;
}
table#schedule th {
  text-align: center;
}
.period {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: #3876BF;
}
.tmp-period {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-color: #3876BF;
}

.shift-box {
  padding: 16px;
  cursor: pointer;
}