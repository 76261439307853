.slider-container {
  position: relative;
  padding-top: 6px;
  cursor: pointer;
  border: 1px solid #e1e1e1;
}

.slider {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.slider-container .timeslider-pointer {
  position: absolute;
  top: 3px;
  width: 2px;
  background-color: rgb(63, 81, 181);
  height: calc(100% - 3px);
}

.timeslider {
  height: 2px;
  background-color: #e1e1e1;
  padding: 6px;
}

.timeslider-container {
  position: relative;
}

.timeslider-container .scale {
  position: absolute;
  top: -1px;
  color: #666;
  padding-left: 2px;
  font-size: 10px;
  pointer-events: none;
}

.slider-handle {
  position: absolute;
  margin-top: -13px;
  margin-left: -6px;
  z-index: 2;
  width: 14px;
  height: 14px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgb(63, 81, 181);
}

.slider-handle:hover {
  margin-left: -8px;
  margin-top: -14px;
  width: 18px;
  height: 18px;
}

.order-events {
  position: relative;
  padding: 10px 0px;
  overflow-y: auto;
  max-height: 210px;
}

.order-events .order {
  position: relative;
  height: 15px;
  background-color: rgba(85,171,165,0.2);
  margin-bottom: 2px;
}

.order-events .order .creation {
  position: absolute;
  float: left;
  background-color: #00a99d;
  width: 3px;
  height: 15px;
  margin-right: 1px;
}

.order-events .order .assigned {
  position: absolute;
  float: left;
  background-color: #03968c;
  width: 3px;
  height: 15px;
  margin-right: 1px;
}

.order-events .order .picked {
  position: absolute;
  float: left;
  background-color: #036d67;
  width: 3px;
  height: 15px;
  margin-right: 1px;
}

.order-events .order .fulfill {
  position: absolute;
  float: left;
  background-color: #014d48;
  width: 3px;
  height: 15px;
  margin-right: 1px;
}