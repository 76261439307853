#google-maps {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.map-infobox {
  background-color: #f1f1f1;
  font-weight: 700;
  opacity: 0.75;
  padding: 6px;
}