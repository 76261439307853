.column-title {
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #3f51b5 !important;
  color: #ffffff !important;
  border-radius: 0px;
  cursor: pointer;
}

@media only screen and (max-width: 1280px) {
  .column-title {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}

.column-title div {
  padding: 0px;
}

.column-title span {
  font-size: 1.5rem;
}

.column-title img {
  margin: 5px 5px 0px 5px;
}

.column-title .MuiCardHeader-avatar {
  background-color: #ff6633;
  height: 58px;
  width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1rem;
  font-weight: 700;
}

.subtitle {
  font-size: 1rem;
}

.column-card {
  margin-bottom: 10px;
}

.column-card .body {
  text-align: center;
  border-top: 1px solid #E1E1E1;
}

.column-card .body b {
  font-size: 1.2rem;
}

.column-card .body .fact {
  font-size: 1.5rem;
  margin-right: 5px;
}

.column-button {
  cursor: pointer;
  justify-content: center;
  background-color: #E1E1E1;
  color: #333333;
  font-weight: 700;
}