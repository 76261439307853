.date-time-range-slider-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
}

.dtrs-with-hot-dates {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e1e1e1;
  padding: 3px;
  max-height: 45px;
  position: relative;
}

.dtrs-with-hot-dates-secondary {
  min-width: 250px;
  margin-left: 10px;
}

.dtrs-with-hot-dates-secondary .MuiInputBase-root {
  padding: 0px 5px;
}

.dtrs-with-hot-dates-secondary .MuiFormControlLabel-root span {
  font-size: 0.75rem;
}
.dtrs-with-hot-dates-secondary .MuiFormControlLabel-root {
  margin-right: 0px;
}

@media only screen and (max-width: 720px) {
  .date-time-range-slider-wrapper {
    flex-direction: column;
    align-items: stretch;
  }
  .dtrs-with-hot-dates {
    flex-direction: column;
    align-items: stretch;
    max-height: unset;
  }
}
