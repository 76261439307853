.filters {
  background-color: #ffffff;
  border-bottom: 1px solid #e1e1e1;
}
.filters.dark {
  background-color: #424242;
  border-bottom: 1px solid #e1e1e1;
}

.MuiGrid-grid-xs-6:nth-child(1) > .filters {
  border-right: 1px solid #e1e1e1;
}

.actions-wrapper {
  padding: 3px;
  display: flex;
}

p.greater-than {
  color: #e1e1e1;
  margin: 5px;
  margin-top: 3px;
}

@media only screen and (max-width: 920px) {
  .greater-than {
    display: none;
  }
}