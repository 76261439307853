.metric-card {
  overflow: unset;
  display: flex;
  flex-direction: row;
}

.metric-card-content {
  display: flex;
  padding: 6px !important;
  flex: 1;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .metric-card button span {
    display: block;
    clear: both;
  }
}